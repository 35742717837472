import React from "react";
import styled from "styled-components";
import { SCREEN } from "styles/screens";
import { getTranslation } from "data/dictionary";
import { ShadowText, TitleContainer, TitleText } from 'components/styled/typography'
import PMImage from 'images/productmanage.png'
import PDImage from 'images/PDImage.png'
import MarketingImage from 'images/Marketing.png'
import BackenndImage from 'images/Backend.png'
import FrontendImage from 'images/Frontend.png'
import Layout from 'layouts/main'
import { Button } from 'components/styled/button'
import { useState } from 'react'
import { useEffect } from 'react'
import fetch from "node-fetch";
 import CourseITem from "./coursesTableItem";




const Main = styled.div`
    overflow-x: hidden;


     .line{
      padding: 0;
    margin: 0;
    width: 1300px;
    margin-left: 150px;
}
`

const LongtermCourses = styled.div`
    margin-top: 100px;
    margin-bottom: 100px;
    overflow-x: hidden;
    
`



const CoursesTable = () => {

    const [shortTermCourses, setShortTermCourses] = useState([]);

    const [longTermCourses, setLongTermCourses] = useState([]);

    const base_url = 'https://hhx6cxn4j0.execute-api.us-east-1.amazonaws.com/production';

    const API_SHORT_TERM = 'https://backend.buildup.am/programs?type=short';

    const API_LONG_TERM = 'https://backend.buildup.am/programs?type=long';


    useEffect(() => {
        fetch(API_SHORT_TERM)
        .then(response => response.json())
        .then(data => setShortTermCourses(data))
     }, [])

     useEffect(() => {
        fetch(API_LONG_TERM)
        .then(response => response.json())
        .then(data => setLongTermCourses(data))
     }, [])


    const [isEnrolled, setIsEnrolled] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const handleClick = () => {
        const iframe = document.getElementById("build-up-extension");
        if (iframe) {
            iframe.style.display = 'block'
            iframe.contentWindow.postMessage({
                type: "BU_TOKEN",
                token: window.localStorage.getItem('token')
            }, "*");
        }
        setIsEnrolled(!isEnrolled);
    }

    useEffect(() => {
        const handleMessage = (event) => {
            if (event.data.type === 'extension-closed') {
                const iframe = document.getElementById("build-up-extension");
                if (iframe) iframe.style.display = "none"
                setIsEnrolled(false);
            }
        }

        window.addEventListener('message', handleMessage);
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);


    return (
        <Layout>
            {/* <BuildUpExtension /> */}
            <Main>
                <TitleContainer style={{ marginBottom: "60px" }}>
                    <TitleText>
                        <h3>
                            Short courses
                        </h3>
                    </TitleText>
                </TitleContainer>
               
                {shortTermCourses.map((course, index) => <CourseITem course={course} index={index} maxIndex={shortTermCourses.length - 1}/>)}

                <LongtermCourses>
                    <TitleContainer style={{ marginBottom: "60px" }}>
                        <TitleText>
                            <h3>
                                Long-term courses
                            </h3>
                        </TitleText>
                    </TitleContainer>

                 
                    {longTermCourses.map((course, index) => <CourseITem course={course} index={index} maxIndex={longTermCourses.length - 1}/>)}
                    

                </LongtermCourses>
            </Main>
        </Layout>
    );
}

export default CoursesTable;
